import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from './../lib/translate';

import Layout from '../layouts/default';
import Helmet from '../components/helmet';
import Breadcrumb from '../component-elements/breadcrumb';
import HeaderBrand from '../brands-component/header-brands';
import CmsImagesBrandText from '../brands-component/content-elements/CmsImagesBrandText/index';
import CmsProductCollection from '../brands-component/content-elements/CmsProductCollection/index';
import CmsMulticolumnBrand from '../brands-component/content-elements/CmsMulticolumnBrand';
import CmsBrandContactComponent from '../content-elements/brandContact';
import CmsImageGallery from '../content-elements/GalleryImages';

import * as styles from './styles/breadcrumb.module.scss'

const components = {
    DatoCmsImagesText: CmsImagesBrandText,
    DatoCmsProductCollectionTeaser: CmsProductCollection,
    DatoCmsMulticolumnText: CmsMulticolumnBrand,
    DatoCmsBrandContact: CmsBrandContactComponent,
    DatoCmsImageGallery: CmsImageGallery,
};

const BrandTemplate = ({
    data: {
        datoCmsBrand: {
            metaRobots,
            metaCanonical,
            textcolorHeader,
            textcolorHeaderMobile,
            seoMetaTags,
            seo,
            name,
            headerImage,
            headerImageMobile,
            logo,
            productCollectionsCount,
            collectionsUrlPath,
            productsUrlPath,
            content,
        },
        datoCmsConfig: {
            productDetailPath,
        },
    },
    pageContext,
}) => {
    const breadcrumbClassnames = [styles.breadcrumb];
    if (styles[`headerTextcolor${textcolorHeader}`]) {
        breadcrumbClassnames.push(styles[`headerTextcolor${textcolorHeader}`]);
    }
    if (styles[`headerMobileTextcolor${textcolorHeaderMobile}`]) {
        breadcrumbClassnames.push(styles[`headerMobileTextcolor${textcolorHeaderMobile}`]);
    }

    return (
        <Layout textcolorHeader={textcolorHeader} textcolorHeaderMobile={textcolorHeaderMobile}>
            <Breadcrumb
                items={[
                    {
                        id: name,
                        name,
                    }
                ]}
                className={breadcrumbClassnames.join(' ')}
            />

            <HeaderBrand
                name={name}
                headerImage={headerImage}
                headerImageMobile={headerImageMobile}
                logo={logo}
                hasCollections={productCollectionsCount > 0}
                collectionsUrlPath={collectionsUrlPath}
                productsUrlPath={productsUrlPath}
            />

            {content.map((singleComponent) => {
                if (typeof singleComponent === 'object' && Object.keys(singleComponent).length > 0) {
                    const Component = components[singleComponent.__typename];
                    return (
                        <Component
                            key={singleComponent.id}
                            lang={pageContext.lang}
                            productDetailPath={productDetailPath}
                            {...singleComponent}
                        />
                    );
                }
                return (
                    <div><h4>This content element can not be rendered.</h4></div>
                );
            })}

            <Helmet
                tags={seoMetaTags}
                metaRobots={metaRobots}
                metaCanonical={metaCanonical}
                title={seo?.title}
                titleFallback={t('brand_details_seo_title_fallback', name, name)}
                description={seo?.description}
                descriptionFallback={t('brand_details_seo_description_fallback', name)}
                url={`${pageContext.pathPrefix}/${pageContext.url}`}
            />
        </Layout>
    );
};

export default BrandTemplate;

BrandTemplate.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
query brandQuery($url: String, $lang: String) {
    datoCmsBrand(url: {eq: $url}, locale: {eq: $lang}) {
        ...headerBrand
        ...contentBrand
        name
        productsUrlPath
        collectionsUrlPath
        productCollectionsCount
        metaCanonical
        metaRobots
        textcolorHeader
        textcolorHeaderMobile
        seoMetaTags {
            __typename
            tags
        }
        seo {
            title
            description
        }
    }
        
    datoCmsConfig(locale: {eq: $lang}) {
        productDetailPath
    }
}
`;
